import request from '@/service/request'
import { getXMenuType } from '@/utils/constant'

//产品分类
export function list(data, menuType = 'other') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/productService/referSize/getReferSizeById',
    method: 'post',
    data
  })
}

export function add(data) {
  return request({
    url: '/api/prod_design/base_group_design',
    method: 'post',
    data
  })
}

export function read(data) {
  return request({
    url: `/api/prod_design/base_group_design${data}/`,
    method: 'get',
    data
  })
}

export function edit(id, data) {
  return request({
    url: '/api/prod_design/base_group_design' + id + '/',
    method: 'put',
    data
  })
}

export function bulkEdit(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/productService/referSizeLayerGroup/batchDisplaySet',
    method: 'post',
    data
  })
}

export function setRotate(list, menuType = 'other') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/productService/referSizeLayerGroup/batchSetRotate',
    method: 'post',
    data: {
      list
    }
  })
}

export function del(id) {
  return request({
    url: '/api/prod_design/base_group_design' + id + '/',
    method: 'delete'
  })
}
