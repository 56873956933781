<template>
  <section
    class="app-container app-flex-col-container verify-component knife-size-check"
    v-loading="loading.createPsd || !isResolved"
    element-loading-text="拼命加载中"
    element-loading-spinner="el-icon-loading"
  >
    <div class="content">
      <div class="product-component-wrapper">
        <KenifeFiles
          ref="kenifeFiles"
          :thumbnails="thumbnails"
          :data="psdListData"
          @loadend="loadend"
          @toggleCurPsd="toggleCurPsd"
        >
          <template #name="{ index }"> 模块{{ convertCurrency(index + 1) }} </template>
        </KenifeFiles>

        <product-component
          v-if="isResolved"
          :data="baseSize"
          :composeImage="false"
          class="base-size-component ml20"
        ></product-component>

        <RotateProductComponent
          v-if="isResolved"
          ref="rotateProductComponent"
          :data="sitePsd"
          :baseSize="rotateBaseSize"
          type="1"
          :rotate="false"
          :composeImage="false"
          class="site-component ml10"
          @rotateChange="rotateChange"
          @change="draggableChange"
        ></RotateProductComponent>

        <div ref="listWrapper" class="canvas-wrapper ml10">
          <el-image
            :src="curPsd.thumbnail || ''"
            :style="{
              width: coverLayer.imgWidth * coverLayer.imgScale + 'px',
              height: coverLayer.imgHeight * coverLayer.imgScale + 'px'
            }"
          />
          <canvas ref="canvas" class="canvas"></canvas>
        </div>
      </div>
    </div>

    <div class="bottom">
      <el-button @click="goBack"> 上一步 </el-button>
      <el-button v-if="canSave" type="primary" @click="doSubmit" :loading="loading.submit"> 保存 </el-button>
      <el-button v-else type="danger"> 所有模块的组块总数与参考尺码的组块数量不相等 </el-button>
    </div>
  </section>
</template>

<script>
import productComponent from './module/productComponent'
import RotateProductComponent from './module/rotateProductComponent'
import KenifeFiles from '@/views/knife/check/module/kenifeFiles'
import kenifeLayer from '@/views/knife/check/module/kenifeLayer'
import { getReferenceSize, changeGroup } from '@/api/product/productApi'
import { setKlProdSite as add } from '@/api/product/protoSizeApi'
import { setRotate } from '@/api/product/referenceSizeMangeApi'
import cloneDeep from 'lodash/cloneDeep'
import { mapGetters, mapMutations } from 'vuex'
import { BOUNDARY, FONT_LAYER, KL_IMAGE_TYPES, OTHER_LAYER } from '@/utils/constant'
import {
  createRandomNum,
  convertCurrency,
  uniqueArray,
  debounce,
  getPicName,
  getValueFromObj,
  getRotatePoint,
  getFigurePath,
  base64ToFile
} from '@/utils'

export default {
  name: 'verifyComponent',
  components: {
    KenifeFiles,
    productComponent,
    RotateProductComponent,
    kenifeLayer
  },
  data() {
    return {
      loading: {
        submit: false,
        createPsd: false
      },
      baseSize: {},
      rotateBaseSize: {},
      size: {},

      psdListData: [],
      curPsd: {},
      thumbnails: [],
      coverLayer: {
        width: 350,
        height: 0,
        imgWidth: 350,
        imgHeight: 0,
        imgScale: 1,
        canvasWidth: 350,
        canvasHeight: 0,
        canvasScale: 1
      },

      canSave: true,
      isFulfilled: false
    }
  },
  computed: {
    ...mapGetters(['knifePsds']),
    psdList({ knifePsds }) {
      return knifePsds.filter(({ files }) => files.length)
    },
    sitePsd({ curPsd: { groups = [] }, baseSize: { base_kl_groups = [] } }) {
      let kl_groups = []
      groups.forEach((group) => {
        // 使用group.order而不是groupsOrder是为了防止每次移动后都会更新sitePsd
        kl_groups[group.order] = group
      })
      base_kl_groups.forEach((item, order) => {
        if (!kl_groups[order])
          kl_groups[order] = {
            id: 'placeholder' + order,
            order
          }
      })
      console.log('sitePsd.kl_groups', kl_groups, this.psdListData)
      return {
        size_name: '一键位置psd',
        kl_groups
      }
    },
    canvasInfo({ baseSize, curPsd: { groups = [], groupsOrder }, coverLayer: { canvasScale } }) {
      let siteGroups = []
      for (const index in groupsOrder) {
        siteGroups[groupsOrder[index]] = groups[index]
      }
      return {
        siteGroups,
        baseSize,
        canvasScale
      }
    },
    isResolved({ isFulfilled, canSave }) {
      return isFulfilled && canSave
    }
  },
  watch: {
    canvasInfo: {
      handler(n) {
        let { canSave, curPsd, coverLayer } = this
        if (
          canSave &&
          curPsd.groupsLen > 0 &&
          n.baseSize.base_kl_groups &&
          n.siteGroups.length >= curPsd.groupsLen &&
          coverLayer.height
        ) {
          // console.log('initCanvas')
          this.initCanvas()
        }
      },
      deep: true
    },
    isResolved(n) {
      console.log(n)
    }
  },

  created() {
    this.getReferenceSize()
    this.createPsdListData()
  },
  methods: {
    ...mapMutations(['SET_IS_GO_BACK']),

    async getReferenceSize() {
      let res = await awaitResolve(
        getReferenceSize({
          id: this.$route.query.primId
        })
      )
      if (res) {
        let {
          detail: { referSize, sizeList: size }
        } = res
        referSize = referSize || {}
        let { referSizePsdConfig, referSizeLayerGroupList } = referSize
        let base_size = {
          ...referSize,
          id: referSize.id,
          size_name: referSize.sizeName,
          base_kl_docs: [
            {
              ...referSizePsdConfig,
              base_psd_path: referSizePsdConfig.psdPath,
              kl_h_dpi: referSizePsdConfig.hdpi,
              kl_image_height: referSizePsdConfig.imageHeight,
              kl_image_width: referSizePsdConfig.imageWidth,
              kl_v_dpi: referSizePsdConfig.vdpi
            }
          ],
          base_kl_groups: referSizeLayerGroupList.map((group) => {
            return {
              ...group,
              base_kl_images: group.referSizeLayerGroupImageList.map((image) => {
                return {
                  ...image,
                  img_url: image.imagePath
                }
              }),
              // id: group.id,
              // name: group.name,
              // order: group.order,
              quick_design_rotate: group.quickDesignRotate,
              show_rotate: group.showRotate
            }
          })
        }
        this.baseSize = base_size || {}
        //复制this.baseSiz
        this.rotateBaseSize = cloneDeep(this.baseSize)
        if (
          this.rotateBaseSize.base_kl_groups &&
          this.rotateBaseSize.base_kl_groups.length &&
          this.$refs.rotateProductComponent
        ) {
          this.$refs.rotateProductComponent.rotateData = this.rotateBaseSize.base_kl_groups.map(
            ({ quick_design_rotate }) => quick_design_rotate || 0
          )
          this.$refs.rotateProductComponent.startRotateData = cloneDeep(this.$refs.rotateProductComponent.rotateData)
        }
        this.size = size || []
        let { coverLayer } = this
        coverLayer.canvasWidth = base_size.base_kl_docs[0].kl_image_width
        coverLayer.canvasHeight = base_size.base_kl_docs[0].kl_image_height
        this.resize()
        // console.log(base_size, size);
        this.validPsdGroupsLen()
      }
    },

    //角度修改
    async submitRotate() {
      const { psdListData } = this
      const allGroups = {}
      psdListData.map((psd) => {
        const { groups = [] } = psd
        groups.map((cItem) => {
          allGroups[cItem.order] = cItem
        })
      })
      console.log('allGroups', allGroups)
      try {
        //order改变，宽高对应
        const { rotateData, list: quickList } = this.$refs.rotateProductComponent
        const { base_kl_groups: list } = this.baseSize
        const existPicData = []
        list.forEach((rotate, index) => {
          existPicData.push({
            quickDesignRotate: rotateData[index],
            quickDesignWidth: quickList[index]?.width,
            quickDesignHeight: quickList[index]?.height,
            id: rotate.id
          })
        })
        const { code } = await setRotate(existPicData)
        return $SUC({ code })
      } catch (err) {
        console.log('err', err)
        this.loading.submit = false
      }
    },

    async doSubmit() {
      this.loading.submit = true
      let {
        psdListData,
        baseSize: { id: referSizeId, base_kl_groups }
      } = this
      console.log('psdListData', psdListData)

      let groupIds = []
      let p = []
      let postData = psdListData.map(({ width, height, groups, groupsOrder, file, thumbnail }, sort) => {
        let tempObj = {
          sort,
          width,
          height,
          referSizeId,
          referSizeLayerGroupUpdateDTOList: groups.map(({ id, top, left }) => {
            let group_id = base_kl_groups[groupsOrder[id]]?.id
            groupIds.push(group_id)
            return {
              // groupsOrder是最新排序
              // group.order只有切换psd文件时才会更新
              id: group_id,
              quickDesignTop: top,
              quickDesignLeft: left
            }
          })
        }
        if (file instanceof File) {
          tempObj.name = file.name.replace('.psd', '')
          p.push(async () => {
            return (tempObj.positionPsdPath = await awaitFormResolve(getFigurePath(file)))
          })
        } else {
          tempObj.name = getPicName(file)
          tempObj.positionPsdPath = file
        }
        p.push(async () => {
          return (tempObj.displayImagePath = await awaitFormResolve(
            getFigurePath(base64ToFile(thumbnail, `${tempObj.name}.jpg`))
          ))
        })
        return tempObj
      })
      groupIds = uniqueArray(groupIds)
      if (groupIds.length !== base_kl_groups.length) {
        this.$message.warning('参考尺码的位置未全部对应完毕')
        this.loading.submit = false
        return
      }
      const success = await promiseAll(p.map((cb) => cb()))
      if (success.some((item) => !item)) {
        this.$message.warning('文件上传失败，请重新保存')
        this.loading.submit = false
        return
      }
      console.log(postData)

      //提交角度
      const rotateSuccess = await this.submitRotate()
      if (!rotateSuccess) {
        this.loading.submit = false
        return
      }

      let res = await awaitResolve(add(postData))
      if (res) {
        this.$message.success('操作成功')
        this.$router.push('/product/maintain/sizeMange')
      }
      this.loading.submit = false
      // console.log(psdListData, params)
    },
    draggableChange(val) {
      let { curPsd } = this
      for (const index in curPsd.groupsOrder) {
        curPsd.groupsOrder[index] = val[index].order
      }
      // console.log(val, curPsd.groupsOrder)
      // console.log('sitePsd.kl_groups', this.sitePsd.kl_groups)
    },

    //角度旋转触发事件
    rotateChange() {
      console.log('rotateChange')
      this.roatetTimer && clearTimeout(this.roatetTimer)
      this.roatetTimer = setTimeout(() => {
        this.initCanvas()
      }, 500)
    },

    toggleCurGroupLayer(groupLayer) {
      this.curGroupLayer = groupLayer
    },
    createPsdListData() {
      if (!this.psdList.length) {
        this.goBack(false, '请先上传位置psd')
        return /*this.$router.replace('/product/maintain/sizeMange')*/
      }

      const psdListData = cloneDeep(this.psdList)
      // console.log('psdListData', psdListData)
      try {
        this.loading.createPsd = true
        let noGroupPsdNames = []
        const p = []
        psdListData.forEach((knifeItem, knifeIndex) => {
          let { files, $oGroupsOrder = [] } = knifeItem
          files.map((file, index) => {
            let url
            if (file.position_psd) {
              url = file = file.position_psd
            } else {
              url = URL.createObjectURL(file)
              // psd文件为新上传时，无$oGroupsOrder
              $oGroupsOrder = []
            }
            p.push(
              new Promise((resolve, reject) => {
                this.PSD.fromURL(url)
                  .then((psd) => {
                    // console.log('psd', psd)
                    const psdInfo = psd.tree().export()
                    // 组块数组
                    psdInfo.groups = []
                    psdInfo.nodes = psd.tree().descendants()
                    let groups = psdInfo.nodes.filter((node) => !(node.type !== 'group' || node.depth() !== 1))
                    // console.log(groups.map(node => `${node.name}-${node.type}-${node.depth()}`), psdInfo.nodes.map(node => `${node.name}-${node.type}-${node.depth()}`))
                    if (groups.length === 0) {
                      noGroupPsdNames.push(file.name)
                      return reject()
                    }
                    // 组块长度：组块全部加载完成后resolve
                    psdInfo.groupsLen = groups.length
                    // 组块排序值: { [id]: order }
                    // id设置为group的index
                    psdInfo.groupsOrder = {}
                    groups.map((node, index) => {
                      let oGroupOrder = $oGroupsOrder.find(({ name }) => name === node.name)?.order ?? index
                      // console.log(node.root().width, node.root().height)
                      psdInfo.width = node.parent.width
                      psdInfo.height = node.parent.height
                      psdInfo.groupsOrder[index] = oGroupOrder
                      // console.log(node)
                      const children = node._children || []
                      const forms = []
                      children.map((item, index) => {
                        const { name, top, left, width, height, text } = item.export() || {}
                        const obj = {
                          name,
                          top,
                          left,
                          width,
                          height
                        }
                        // console.log('psdInfo.groups', index, node.name, item.type, item)
                        if (item.type !== 'group') {
                          obj.imgUrl = item.layer.image.toBase64()
                        }
                        forms.push(obj)
                      })
                      node.forms = forms
                      // console.log('psdInfo.groups', index, node.name, forms, children)
                      this.getGroupImgInfo(forms, node).then((img) => {
                        // console.log(img, this.$refs.canvas)
                        // console.log('psdInfo.groups', index, node.name, img)
                        this.$set(psdInfo.groups, index, {
                          id: index,
                          order: oGroupOrder,
                          name: node.name,
                          ...img,
                          kl_images: [
                            {
                              ...img,
                              img_url: img.src
                            }
                          ]
                        })
                        if (psdInfo.groups.length === psdInfo.groupsLen) {
                          resolve(psd)
                        }
                      })
                    })

                    const png = psd.image.toPng()
                    psdInfo.thumbnail = png.src
                    this.$nextTick(() => {
                      this.$set(this.thumbnails, knifeIndex, png.src)
                    })
                    psdInfo.file = file

                    //在knifePsds添加额外psd信息
                    Object.assign(knifeItem, psdInfo)
                  })
                  .catch((err) => {
                    reject()
                  })
              })
            )
          })
        })
        Promise.all(p)
          .then((res) => {
            // console.log(res)
            console.log('psdListData', psdListData)
            this.psdListData = psdListData
            this.toggleCurPsd(0)
            this.validPsdGroupsLen()
          })
          .catch((err) => {
            this.canSave = false
            this.goBack(
              true,
              noGroupPsdNames.length ? `${noGroupPsdNames.join('，')}等psd无组块` : 'psd文件错误，请上传正确文件'
            )
          })
      } catch (err) {
        this.loading.createPsd = false
        this.goBack(true, '发生未知错误')
      }
    },
    toggleCurPsd(index) {
      let { psdListData, coverLayer, curPsd } = this
      console.log(curPsd)
      for (const index in curPsd.groupsOrder) {
        curPsd.groups[index].order = curPsd.groupsOrder[index]
      }
      this.curPsd = curPsd = psdListData[index]

      this.$set(this.curPsd, 'groups', curPsd.groups)
      this.$set(this.curPsd, 'groupsOrder', curPsd.groupsOrder)
      coverLayer.imgWidth = curPsd.width
      coverLayer.imgHeight = curPsd.height
      this.$nextTick(function () {
        coverLayer.imgScale = Math.min(coverLayer.width / coverLayer.imgWidth, coverLayer.height / coverLayer.imgHeight)
        // console.log(this.curPsd)
      })
    },
    loadend() {
      this.loading.createPsd = false
    },
    validPsdGroupsLen() {
      let {
        baseSize: { base_kl_groups },
        psdListData,
        psdList
      } = this
      console.log(base_kl_groups, psdListData)
      if (base_kl_groups && psdListData.length === psdList.length) {
        this.isFulfilled = true
        if (
          base_kl_groups.length !==
          psdListData.reduce((total, item) => {
            return total + (getValueFromObj(item, 'groups.length') ?? 0)
          }, 0)
        ) {
          this.canSave = false
          this.goBack(true, '所有模块的组块总数与参考尺码的组块数量不相等')
        }
      }
    },

    initCanvas() {
      let {
        coverLayer,
        canvasInfo: { baseSize, siteGroups, canvasScale }
      } = this
      // console.log(JSON.parse(JSON.stringify(baseSize)), JSON.parse(JSON.stringify(siteGroups)), canvasScale)
      // console.log('siteGroups', JSON.parse(JSON.stringify(siteGroups)))
      const c = this.$refs.canvas
      if (!c) return
      const ctx = c.getContext('2d')
      const canvasSize = Math.max(coverLayer.canvasWidth, coverLayer.canvasHeight) * 1.5
      c.width = canvasSize * coverLayer.canvasScale
      c.height = canvasSize * coverLayer.canvasScale
      ctx.clearRect(0, 0, c.width, c.height)

      baseSize.base_kl_groups?.forEach((baseGroup, index) => {
        const { rotateProductComponent } = this.$refs

        let siteGroup = siteGroups[index]
        if (!siteGroup || !siteGroup.src) return
        let quickDesignRotate = 0

        if (rotateProductComponent) {
          if (rotateProductComponent.rotateData.length && rotateProductComponent.rotateData[index])
            quickDesignRotate = rotateProductComponent.rotateData[index]
        }
        console.log('quickDesignRotate', quickDesignRotate)
        let { img_url, width, height } = baseGroup.base_kl_images[0]
        this.canvasDrawImg(ctx, {
          src: img_url,
          width: width * canvasScale,
          height: height * canvasScale,
          top: siteGroup.top * canvasScale,
          left: siteGroup.left * canvasScale,
          isCache: true,
          rotate: quickDesignRotate
        })
      })
    },

    async getGroupImgInfo(children, { width = 0, height = 0, top, left }) {
      // console.log(children)
      const c = document.createElement('canvas')
      const ctx = c.getContext('2d')
      let nodeInfo = {
        width,
        height,
        top,
        left
      }
      children.forEach((child) => {
        if (nodeInfo.width < child.width) nodeInfo.width = child.width
        if (nodeInfo.height < child.height) nodeInfo.height = child.height
        if (nodeInfo.top === undefined)
          Object.assign(nodeInfo, {
            top: child.top,
            left: child.left
          })
        if (nodeInfo.top > child.top) nodeInfo.top = child.top
        if (nodeInfo.left > child.left) nodeInfo.left = child.left
      })
      c.width = nodeInfo.width
      c.height = nodeInfo.height
      await Promise.all(
        children.map((child, index) =>
          this.canvasDrawImg(ctx, {
            src: child.imgUrl,
            left: children[index].left - nodeInfo.left,
            top: children[index].top - nodeInfo.top
          })
        )
      )
      return {
        src: c.toDataURL('image/png'),
        ...nodeInfo
      }
    },
    async canvasDrawImg(ctx, { src, width, height, top, left, isCache, rotate = 0, dirX, dirY }) {
      //rotate表示的是 quick_design_rotate 一键定制角度
      let imgEle = null
      if (rotate) {
        let [err, roatateDom] = await awaitWrap(
          this.getCreatedImg(src, isCache, {
            rotate
          })
        )
        const { scaleY, scaleX, dom, iW, iH, cW, cH } = roatateDom
        imgEle = dom
        // left = left - dirX * scaleX
        // top = top - dirY * scaleY
        // left = left - (width * scaleX - width) / 2 + (cW - iW) / 2 / (cW / width)
        // top = top - (height * scaleY - height) / 2 + (cH - iH) / 2 / (cH / height)
        // left = left -  (cW - iW) / 2 / (cW / width)
        //  top = top -  (cH - iH) / 2 / (cH / height)
        width = scaleX * width
        height = scaleY * height
      } else {
        let [err, dom] = await awaitWrap(
          this.getCreatedImg(src, isCache, {
            rotate
          })
        )
        imgEle = dom
      }

      if (imgEle) {
        let tempArr = [left, top, width, height].filter((item) => item || item === 0)
        ctx.drawImage(imgEle, ...tempArr)
        return true
      }
    },

    //获取旋转之后模块的画布
    getCanvaRactsSize({ height, width, rotate }) {
      //旋转中心
      const { abs } = Math
      let [w, h] = [0, 0]
      const [oX0, oY0] = [0, 0]
      const [rX0, rY0] = [-width / 2, height / 2]
      const [rX1, rY1] = [width / 2, height / 2]
      const [rX2, rY2] = [width / 2, -height / 2]
      const [rX3, rY3] = [-width / 2, -height / 2]

      const { x: x0, y: y0 } = getRotatePoint({ rX: rX0, rY: rY0, oX: oX0, oY: oY0, angle: rotate })
      const { x: x1, y: y1 } = getRotatePoint({ rX: rX1, rY: rY1, oX: oX0, oY: oY0, angle: rotate })
      const { x: x2, y: y2 } = getRotatePoint({ rX: rX2, rY: rY2, oX: oX0, oY: oY0, angle: rotate })
      const { x: x3, y: y3 } = getRotatePoint({ rX: rX3, rY: rY3, oX: oX0, oY: oY0, angle: rotate })
      if (rotate <= 90 || (rotate > 180 && rotate <= 270)) {
        w = abs(x1) + abs(x3)
        h = abs(y0) + abs(y2)
      } else {
        w = abs(x0) + abs(x2)
        h = abs(y1) + abs(y3)
      }
      return {
        w,
        h
      }
    },

    getCreatedImg(src, isCache, { rotate = 0 }) {
      let { createdImgCache = {} } = this
      return new Promise((resolve) => {
        let img = createdImgCache[src]
        // if (img) return resolve(img)
        img = new Image()
        img.onload = () => {
          if (isCache) {
            createdImgCache[src] = img
            this.createdImgCache = createdImgCache
          }
          if (rotate) {
            let c = document.createElement('canvas')
            const ctx = c.getContext('2d')
            const { width, height } = img
            const { w: cW, h: cH } = this.getCanvaRactsSize({
              width,
              height,
              rotate
            })

            c.width = cW
            c.height = cH
            ctx.translate(cW / 2, cH / 2)
            ctx.rotate((rotate * Math.PI) / 180)
            ctx.drawImage(img, -width / 2, -height / 2)

            // ctx.rotate((-rotate * Math.PI) / 180)
            // ctx.translate(-cW / 2, -cH / 2)
            // ctx.rect(0, 0, cW, cH)
            // ctx.fill()
            // let c1 = document.createElement('canvas')
            // const ctx1 = c1.getContext('2d')
            // c1.width = width
            // c1.height = height
            // ctx1.drawImage(c, 0, 0, width, height)
            resolve({
              dom: c,

              scaleX: cW / width,
              scaleY: cH / height,
              iW: width,
              iH: height,
              cW,
              cH
            })
          }
          resolve(img)
        }
        img.src = src
      })
    },
    resize() {
      let {
        coverLayer,
        $refs: { listWrapper }
      } = this
      if (coverLayer.isResize) return
      coverLayer.isResize = true
      let resize = debounce(() => {
        if (listWrapper) coverLayer.height = listWrapper.clientHeight
        coverLayer.canvasScale = Math.min(
          coverLayer.width / coverLayer.canvasWidth,
          coverLayer.height / coverLayer.canvasHeight
        )
        coverLayer.imgScale = Math.min(coverLayer.width / coverLayer.imgWidth, coverLayer.height / coverLayer.imgHeight)
      }, 500)
      this.$nextTick(function () {
        resize()
        window.addEventListener('resize', resize)
        this.$once('hook:destroyed', () => {
          window.removeEventListener('resize', resize)
        })
      })
    },

    goBack(isGoBack = true, msg) {
      this.isFulfilled = true
      if (msg) this.$message.warning(msg)
      this.SET_IS_GO_BACK(isGoBack)
      setTimeout(() => {
        this.$router.back()
      }, 1000)
    },
    convertCurrency
  }
}
</script>

<style lang="scss" scoped>
.knife-size-check {
  .content {
    overflow: auto;
  }
  .product-component-wrapper {
    height: calc(100% - 20px);
  }
  .base-size-component {
    min-width: 200px;
  }
  .site-component {
    min-width: 250px;
  }
  .canvas-wrapper {
    white-space: nowrap;
    overflow-y: hidden;
    overflow-x: auto;
  }
}
</style>
